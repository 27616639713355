import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronLeft, FiEdit, FiPlus, FiSave, FiTrash, FiX } from 'react-icons/fi';

import { useApiData } from '../contexts/api-data';

const Subjects = () => {
    const apiData = useApiData();

    const [editing, setEditing] = useState(-1);

    const nameInput = useRef(null);
    const aliasInput = useRef(null);

    function cancel() {
        const subject = apiData.subjects[editing];
        if (subject.new) {
            apiData.removeSubject(editing);
        }
        setEditing(-1);
    }

    async function deleteSubject(index) {
        const deleted = await apiData.removeSubject(index);
        if (deleted) {
            if (index === editing) {
                setEditing(-1);
            }
            else if (editing > index) {
                setEditing(editing-1);
            }
        }
    }

    function newSubject() {
        setEditing(apiData.subjects.length);
        apiData.newSubject();
    }

    async function upsertSubject() {
        const name = nameInput.current.value;
        const alias = aliasInput.current.value;
        const upserted = apiData.upsertSubject(editing, name, alias);
        if (upserted) {
            setEditing(-1);
        }
    }

    return (
        <>
            <div className="main-header">
                <Link to="/dashboard" className="header-link"><FiChevronLeft /></Link>
                <h2>Disciplinas</h2>
                <button onClick={() => newSubject()} className="header-link"><FiPlus /></button>
            </div>

            {apiData.subjects && apiData.subjects.length > 0
                ? (
                    <ul className="main-content">
                        {apiData.subjects.map((subject, index) => (
                            <li key={subject._id || `${index}`}>
                                {editing === index
                                    ? <>
                                        <input
                                            type="text"
                                            defaultValue={subject.name}
                                            placeholder="Nome"
                                            ref={nameInput}
                                        />
                                        <input
                                            type="text"
                                            defaultValue={subject.alias}
                                            placeholder="Alias"
                                            ref={aliasInput}
                                        />
                                        <button
                                            className="header-link"
                                            onClick={() => upsertSubject()}
                                        >
                                            <FiSave />
                                        </button>
                                        <button
                                            className="header-link"
                                            onClick={() => cancel()}
                                        >
                                            <FiX />
                                        </button>
                                    </>
                                    : <>
                                        <span>{subject.name}</span>
                                        <span>{subject.alias}</span>
                                        <button
                                            className="header-link"
                                            onClick={() => setEditing(index)}
                                        >
                                            <FiEdit />
                                        </button>
                                        <button
                                            className="header-link"
                                            onClick={() => deleteSubject(index)}
                                        >
                                            <FiTrash />
                                        </button>
                                    </>
                                }
                            </li>
                        ))}
                    </ul>
                ) : undefined
            }
        </>
    );
}

export default Subjects;
