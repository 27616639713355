import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '../../services/api';

import './styles.css';

function Register({ history }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [valid, setValid] = useState(false);

    const confirmPassword = useRef(null);

    async function handleSubmit(event) {
        event.preventDefault();
        
        if (valid) {
            await api.post('/auth/register', { email, password, name: '' });

            history.push('/');
        }
    }

    function validate() {
        if (password !== confirmPassword.current.value) {
            confirmPassword.current.setCustomValidity('Senhas não equivalem');
            setValid(false);
        }
        else {
            confirmPassword.current.setCustomValidity('');
            setValid(true);
        }
    }
    
    return (
        <div className="register-container">
            <form className="register-form" onSubmit={handleSubmit}>
                <h1 className="register-title">Exam.IO Registro</h1>
                <input
                    className="register-input"
                    type="text"
                    name="email"
                    value={email}
                    id="email"
                    placeholder="Email"
                    onChange={event => setEmail(event.target.value)}
                    required
                />
                {/* <input type="email" name="email" value={email} id="email" onChange={event => setEmail(event.target.value)} /> */}
                <input
                    className="register-input"
                    type="password"
                    name="password"
                    value={password}
                    id="password"
                    placeholder="Senha"
                    onChange={event => setPassword(event.target.value)}
                    required
                />
                <input
                    className="register-input"
                    type="password"
                    name="confirm-password"
                    id="confirm-password"
                    placeholder="Confirmar senha"
                    ref={confirmPassword}
                    onChange={validate}
                    required
                />
                <button className="register-button" type="submit">Registrar</button>
                <Link to="/">Já tenho conta</Link>
            </form>
        </div>
    );
}

export default Register;
