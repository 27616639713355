import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { FiDownload, FiX } from 'react-icons/fi';

import jspdf from 'jspdf';

import api from '../../services/api';
import { useApiData } from '../../contexts/api-data';

const CompileExam = ({ examId }) => {
    const apiData = useApiData();
    const amountInput = useRef(null);

    const maxPageLines = 45;
    const maxLineChars = 50;

    function subject(exam) {
        if (apiData.subjects) {
            const subject = apiData.subjects.find(subject => subject._id === exam.subject);
            return subject.alias || subject.name;
        }
        return null;
    }

    async function compileExam() {
        const amount = amountInput.current.value;
        const response = await api.get(`/exams/${examId}/compile`, {
            params: {
                amount,
            },
        });
        return response.data;
    }

    function divide(list, maximumLength) {
        const result = [];
        let current = [];
        let currentLength = 0;
        list.forEach(item => {
            if (currentLength + item.length > maximumLength) {
                result.push(current);
                current = [];
                currentLength = 0;
            }
            current.push(item);
            currentLength += item.length;
        });
        result.push(current);
        return result;
    }

    function adjustLine(line) {
        const dividedLine = divide(line.split(' '), maxLineChars);
        return dividedLine.map(line => line.join(' '));
    }

    function adjustQuestion(question, compilation, index) {
        const enunciation = `${index+1}. ${question.enunciation}`;
        let enunLines = adjustLine(enunciation);
        let respLine = `${index+1} `;
        compilation.options
            .map(index => question.options[index])
            .forEach((option, index) => {
                const optionChar = String.fromCharCode(97+index);
                respLine += ` ${optionChar}`;
                const optionLine = `${optionChar}) ${option}`;
                const optionLines = adjustLine(optionLine);
                enunLines = [ ...enunLines, ...optionLines ];
            });
        enunLines.push('');

        return {
            enunLines,
            respLine,
        };
    }

    function drawCode(doc, code) {
        const binaryCode = [...Number(code).toString(2)].reverse();

        // rect for boundary
        doc.rect(20, 23, 12, 6);

        const [w, h] = [3, 3];
        binaryCode.forEach((bit, index) => {
            if (bit === '1') {
                const [x, y] = [20+Math.floor(index/2)*w, 23+(index%2)*h];
                
                doc.rect(x, y, w, h, 'F');
            }
        });
    }

    function renderEnunciationParagraphs(doc, enunParagraphs, code) {
        let curPageLines = 0;

        doc.addPage('a4', 'portrait');
        enunParagraphs.forEach((paragraph, index) => {
            if (curPageLines + paragraph.length > maxPageLines) {
                doc.addPage('a4', 'portrait');
                curPageLines = 0;
            }

            doc.text(paragraph, 20, 20 + curPageLines*6.5, {
                lineHeightFactor: index===0 ? 1.4 : 1.15,
            });
            curPageLines += paragraph.length;
        });

        drawCode(doc, code);
    }

    function renderResponseCardPage(doc, respCardHeaderLines, respCardQuestionsLines, code) {
        // adding page for response-card
        doc.addPage('a4', 'portrait');

        doc.text(respCardHeaderLines, 20, 20, {
            lineHeightFactor: 1.4,
        });

        drawCode(doc, code);

        doc.text(respCardQuestionsLines, 20, 70, {
            lineHeightFactor: 2,
        });
    }

    function generateExamTypePages(doc, exam, compilation) {
        const examSubject = subject(exam);
        const { code } = compilation;

        const codeLine = `          Código: ${code+1}`;

        const enunciationParagraphs = [
            [
                exam.name,
                codeLine,
                examSubject,
                '',
            ],
        ];
        
        const responseCardHeaderLines = [
            `${exam.name} (Cartão-resposta)`,
            codeLine,
            examSubject,
            '',
            'Nome: ________________________________',
            '',
        ];

        const responseCardQuestionsLines = [];

        compilation.order
            .map(({ question }, index) => adjustQuestion(exam.questions[question], compilation.order[index], index))
            .forEach(({ enunLines, respLine }) => {
                enunciationParagraphs.push(enunLines);
                responseCardQuestionsLines.push(respLine);
            });

        doc.setFontType("normal");
        renderEnunciationParagraphs(doc, enunciationParagraphs, code);
        doc.setFontType("bold");
        renderResponseCardPage(doc, responseCardHeaderLines, responseCardQuestionsLines, code);
    }

    function generatePDF(exam, compilations) {
        const doc = new jspdf({
            orientation: 'portrait',
            format: 'a4',
        });

        doc.deletePage(1);

        compilations.forEach(compilation => {
            generateExamTypePages(doc, exam, compilation);
        });

        const pdfFileName = `[${subject(exam)}]${exam.name}.pdf`;
        doc.save(pdfFileName);
    }

    async function handleCompile(close) {
        const { exam, compilations } = await compileExam();
        
        generatePDF(exam, compilations);

        if (close && typeof close === 'function') {
            close();
        }
    }

    return (
        <Popup modal trigger={<Link title="Baixar PDF" className="header-link" to="#"><FiDownload /></Link>} position="center center">
            {close => (
                <div className="modal">
                    <div className="modal-header">
                        <h3>BAIXAR PDF</h3>
                        <Link to="#" className="close-button" onClick={close}>
                            <FiX />
                        </Link>
                    </div>
                    <div className="modal-input-group">
                        <label htmlFor="amount">Quantos tipos de exame?</label>
                        <input
                            type="number"
                            min="1"
                            id="amount"
                            defaultValue={14}
                            ref={amountInput}
                        />
                    </div>
                    <div className="modal-buttons">
                        <button className="cancel-button" onClick={close}>Cancelar</button>
                        <button className="create-button" onClick={() => handleCompile(close)}>Baixar</button>
                    </div>
                </div>
            )}
        </Popup>
    );
};

export default CompileExam;
