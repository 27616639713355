import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { FiChevronLeft, FiFilter, FiPlus, FiX } from 'react-icons/fi';

import { useApiData } from '../../contexts/api-data';

const Questions = ({ history }) => {
    const apiData = useApiData();
    const subjectsMapInitial = {};
    if (apiData.subjects) {
        apiData.subjects.forEach(subject => {
            subjectsMapInitial[subject._id] = {
                subject,
                filtered: true,
            };
        });
    }
    const [subjectsMap, setSubjectsMap] = useState(subjectsMapInitial);
    const [filterFormVisible, setFilterFormVisible] = useState(false);
    const [newSubject, setNewSubject] = useState(false);

    const subjectSelect = useRef(null);
    const subjectNameInput = useRef(null);
    const subjectAliasInput = useRef(null);

    async function handleCreateQuestion() {
        const subjectValue = subjectSelect.current.value;
        const newSubject = subjectValue === 'new' ? {
            name: subjectNameInput.current.value,
            alias: subjectAliasInput.current.value,
        } : {};
        const questionId = await apiData.newQuestion(subjectValue, newSubject);
        if (questionId) {
            history.push(`/dashboard/questions/${questionId}`);
        }
    }

    function changeSubjectValue(event) {
        const { value } = event.target;
        if (value === 'new') {
            setNewSubject(true);
        }
        else if (newSubject) {
            setNewSubject(false);
        }
    }

    function toggleFilterForm() {
        setFilterFormVisible(!filterFormVisible);
    }

    function toggleSubjectFiltered(subjectId) {
        const newSubjectsMap = {
            ...subjectsMap,
        };
        newSubjectsMap[subjectId].filtered = !newSubjectsMap[subjectId].filtered;
        setSubjectsMap(newSubjectsMap);
    }

    return (
        <>
            <div className="main-header">
                <Link to="/dashboard" className="header-link"><FiChevronLeft /></Link>
                <h2>Questões</h2>
                <Popup modal trigger={<Link to="#" className="header-link"><FiPlus /></Link>} position="center center">
                    {close => (
                        <div className="modal">
                            <div className="modal-header">
                                <h3>NOVA QUESTÃO</h3>
                                <Link to="#" className="close-button" onClick={close}>
                                    <FiX />
                                </Link>
                            </div>
                            <div className="modal-input-group">
                                <label htmlFor="subject">Disciplina</label>
                                <select id="subject" onChange={changeSubjectValue} ref={subjectSelect}>
                                    <option value="-1">Selecione uma disciplina</option>
                                    {apiData.subjects && apiData.subjects.map(subject => (
                                        <option key={subject._id} value={subject._id}>
                                            {subject.alias || subject.name}
                                        </option>
                                    ))}
                                    <option value="new">Nova disciplina</option>
                                </select>
                                {newSubject && (
                                    <>
                                        <input
                                            type="text"
                                            placeholder="Nome"
                                            ref={subjectNameInput}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Alias"
                                            ref={subjectAliasInput}
                                        />
                                    </>
                                )}
                            </div>
                            <div className="modal-buttons">
                                <button className="cancel-button" onClick={close}>Cancelar</button>
                                <button className="create-button" onClick={handleCreateQuestion}>Criar</button>
                            </div>
                        </div>
                    )}
                </Popup>
            </div>

            {apiData.questions && (
                <ul className="main-content">
                    <li className="main-content-header">
                        <div>Enunciado</div>
                        <div>Disciplina</div>
                        <div>Última alteração</div>
                        <div className="list-actions">
                            <button
                                className="header-link"
                                onClick={toggleFilterForm}
                            >
                                <FiFilter />
                            </button>
                        </div>
                    </li>
                    {filterFormVisible && apiData.subjects && (
                        <li className="filter-form">
                            {apiData.subjects.map(subject => (
                                <div key={subject._id}>
                                    <input
                                        type="checkbox"
                                        id={`filter-subject-${subject._id}`}
                                        defaultChecked={subjectsMap[subject._id] && subjectsMap[subject._id].filtered}
                                        onChange={() => toggleSubjectFiltered(subject._id)}
                                    />
                                    <label htmlFor={`filter-subject-${subject._id}`}>
                                        {subject.name}
                                    </label>
                                </div>
                            ))}
                        </li>
                    )}
                    {apiData.questions.map(question => {
                        const subjectFilter = subjectsMap[question.subject];
                        const { filtered } = {
                            filtered: true,
                            ...subjectFilter,
                        };
                        let questionSubject;
                        if (subjectFilter) {
                            const { alias, name } = subjectFilter.subject;
                            questionSubject = alias || name;
                        }
                        return filtered && (
                            <Link key={question._id} className="main-content-link" to={`/dashboard/questions/${question._id}`}>
                                <li>
                                    <div>{question.enunciation}</div>
                                    <div>{questionSubject || question.subject}</div>
                                    <div>{new Intl.DateTimeFormat('pt-BR').format(new Date(question.updatedAt))}</div>
                                </li>
                            </Link>
                        );
                    })}
                </ul>
            )}
        </>
    );
}

export default Questions;
