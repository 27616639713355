import React from 'react';

import Routes from './routes';

import './App.css';

const App = () => (
  <Routes />
);

export default App;
