import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import ApiDataProvider from '../../contexts/api-data';

import PrivateRoute from '../../components/private-route';

import Dashboard from '../../components/dashboard';
import Exams from '../../components/exams';
import SingleExam from '../../components/exams/single';
import Questions from '../../components/questions';
import SingleQuestion from '../../components/questions/single';
import Subjects from '../../components/subjects';

const Routes = () => (
    <ApiDataProvider>
        <BrowserRouter>
            <Switch>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/dashboard/exams" component={Exams} />
                <PrivateRoute path="/dashboard/exams/:examId" component={SingleExam} />
                <PrivateRoute exact path="/dashboard/questions" component={Questions} />
                <PrivateRoute path="/dashboard/questions/:questionId" component={SingleQuestion} />
                <PrivateRoute path="/dashboard/subjects" component={Subjects} />
            </Switch>
        </BrowserRouter>
    </ApiDataProvider>
);

export default Routes;