import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronLeft, FiPlus, FiSave, FiTrash } from 'react-icons/fi';

import api from '../../services/api';
import { useApiData } from '../../contexts/api-data';

const SingleQuestion = ({ history, match }) => {
    const apiData = useApiData();
    const [question, setQuestion] = useState(null);
    const [options, setOptions] = useState([]);

    const enunciationInput = useRef(null);

    const { questionId } = match.params;

    let subject;
    if (question && question.subject) {
        const subjectObject = apiData.subjects.find(sub => sub._id === question.subject);
        subject = subjectObject
            ? (subjectObject.alias || subjectObject.name)
            : question.subject;
    }

    useEffect(() => {
        (async function () {
            const response = await api.get(`/questions/${questionId}`);

            const { question } = response.data;
            setQuestion(question);
            if (question.options.length === 0) {
                question.options.push('');
            }
            setOptions(question.options);
        })();
    }, [questionId]);

    function changeOption(event, index) {
        const newValue = event.target.value;
        options[index] = newValue;
        setOptions([...options]);
    }

    async function handleSave() {
        question.enunciation = enunciationInput.current.value;
        question.options = options;
        if (await apiData.updateQuestion(question)) {
            history.push('/dashboard/questions');
        }
    }

    function removeOption(index) {
        options.splice(index, 1);
        setOptions(options);
    }

    return (
        <>
            <div className="main-header">
                <Link to="/dashboard/questions" className="header-link"><FiChevronLeft /></Link>
                <h2>Questão</h2>
                <Link title="Salvar" className="header-link" to="#" onClick={handleSave}><FiSave /></Link>
            </div>

            {question && question._id && (
                <div className="main-single-content">
                    <div className="exam-basic-info">
                        <input
                            type="text"
                            defaultValue={question.enunciation.trim()}
                            ref={enunciationInput}
                            placeholder="Enunciado"
                        />
                        <span>{subject || ''}</span>
                    </div>
                    <ul className="question-option-list">
                        {options.map((option, index) => (
                            <li key={index}>
                                <input
                                    id={index}
                                    type="text"
                                    value={option}
                                    onChange={event => changeOption(event, index)}
                                    placeholder={index===0 ? 'Gabarito' : 'Opção'}
                                />
                                <Link
                                    className="header-link"
                                    onClick={() => removeOption(index)}
                                >
                                    <FiTrash />
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <Link className="header-link" onClick={() => setOptions(options.concat(''))}><FiPlus /></Link>
                </div>
            )}
        </>
    );
}

export default SingleQuestion;
