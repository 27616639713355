import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '../../services/api';
import { isAuthenticated, login } from '../../auth';

import './styles.css';

function Login({ history }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (isAuthenticated()) {
            history.push('/dashboard');
        }
    });

    async function handleSubmit(event) {
        event.preventDefault();
        
        const response = await api.post('/auth/authenticate', { email, password });

        const { user, token } = response.data;

        login(user, token);

        history.push('/dashboard');
    }
    
    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <h1 className="login-title">Exam.IO Login</h1>
                <input className="login-input" type="text" name="email" value={email} id="email" placeholder="Email" onChange={event => setEmail(event.target.value)} />
                {/* <input type="email" name="email" value={email} id="email" onChange={event => setEmail(event.target.value)} /> */}
                <input className="login-input" type="password" name="password" value={password} id="password" placeholder="Senha" onChange={event => setPassword(event.target.value)} />
                <button className="login-button" type="submit">Entrar</button>
                <Link to="/register">Não tenho conta</Link>
            </form>
        </div>
    );
}

export default Login;
