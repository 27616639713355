import React, { createContext, useContext, useEffect, useState } from 'react';

import api from '../services/api';

const ApiDataContext = createContext({});

export default ({ children }) => {
    const [subjects, setSubjects] = useState([]);
    const [exams, setExams] = useState([]);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        (async function () {
            const response = await api.get('/subjects');
            setSubjects(response.data.subjects);

            (async function () {
                const response = await api.get('/exams');
                setExams(response.data.exams);
            })();

            (async function () {
                const response = await api.get('/questions');
                setQuestions(response.data.questions);
            })();
        })();
    }, []);

    async function newExam(name, subject, newSubjectObject) {
        if (name && subject !== '-1') {
            if (subject === 'new') {
                subject = await newSubject(newSubjectObject);
                if (!subject) return null;
            }
            const response = await api.post('/exams', {
                name,
                subject,
            });
            const { exam } = response.data;

            exams.push(exam)
            setExams(exams);

            return exam._id;
        }
    }

    async function newQuestion(subject, newSubjectObject) {
        if (subject !== '-1') {
            if (subject === 'new') {
                subject = await newSubject(newSubjectObject);
                if (!subject) return null;
            }
            const response = await api.post('/questions', {
                subject,
                enunciation: ' ',
                options: [],
            });
            const { question } = response.data;

            questions.push(question);
            setQuestions(questions);

            return question._id;
        }
    }

    async function newSubject(subject) {
        if (subject) {
            if (!subject.name || subject.name.length === 0) return null;
            const index = subjects.length;
            subjects.push({ new: true });
            const upsertResult = await upsertSubject(index, subject.name, subject.alias);
            if (!upsertResult) return null;
            subject = subjects[index]._id;
        }
        else {
            subjects.push({ new: true });
        }
        setSubjects(subjects);
        return subject;
    }

    async function removeExam(index) {
        const exam = exams[index];
        try {
            await api.delete(`/exams/${exam._id}`);
            const newExams = exams.slice();
            newExams.splice(index, 1);
            setExams(newExams);
        }
        catch {}
    }

    async function removeSubject(index) {
        const subject = subjects[index];
        if (!subject.new) {
            try {
                await api.delete(`/subjects/${subject._id}`);
            }
            catch {
                return false;
            }
        }
        const newSubjects = subjects.slice();
        newSubjects.splice(index, 1);
        setSubjects(newSubjects);

        return true;
    }

    async function updateExam(exam) {
        try {
            await api.put(`/exams/${exam._id}`, exam);
            const oldExamIndex = exams.findIndex(e => e._id === exam._id);
            exams[oldExamIndex] = exam;
            setExams(exams);
            return true;
        }
        catch {}
    }

    async function updateQuestion(question) {
        try {
            await api.put(`/questions/${question._id}`, question);
            const oldQuestionIndex = questions.findIndex(q => q._id === question._id);
            questions[oldQuestionIndex] = question;
            setQuestions(questions);
            return true;
        }
        catch {}
    }

    async function upsertSubject(index, name, alias) {
        const subject = subjects[index];
        subject.name = name;
        subject.alias = alias;
        try {
            if (subject.new) {
                const response = await api.post('/subjects', subject);
                subject._id = response.data.subject._id;
                delete subject.new;
            }
            else {
                await api.put(`/subjects/${subject._id}`, subject);
            }

            return true;
        }
        catch {
            return false;
        }
    }

    return (
        <ApiDataContext.Provider
            value={{
                subjects, newSubject, removeSubject, upsertSubject,
                exams, newExam, removeExam, updateExam,
                questions, newQuestion, updateQuestion,
            }}
        >
            {children}
        </ApiDataContext.Provider>
    );
};

export function useApiData() {
    return useContext(ApiDataContext);
};
