import React from 'react';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { FiFile, FiCheckCircle, FiBook } from 'react-icons/fi';

const Dashboard = () => (
    <div className="dashboard-content">
        <IconContext.Provider value={{ color: '#444', size: '3em' }}>
            <Link className="dashboard-link" to="/dashboard/exams">
                <FiFile />
                <span>Exames</span>
            </Link>
            <Link className="dashboard-link" to="/dashboard/subjects">
                <FiBook />
                <span>Disciplinas</span>
            </Link>
            <Link className="dashboard-link" to="/dashboard/questions">
                <FiCheckCircle />
                <span>Questões</span>
            </Link>
        </IconContext.Provider>
    </div>
);

export default Dashboard;