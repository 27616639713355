import React from 'react';
import { Link } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';

import { logout } from '../../auth';
import Routes from './routes';
import './styles.css';

function Dashboard() {
    return (
        <div className="dashboard-root">
            <header className="header">
                <h1 className="header-logo">Exam.IO</h1>
                {/* <input type="search" placeholder="Digite sua busca aqui" name="search" id="search" /> */}
                <Link title="Logout" to="#" className="header-logout" onClick={logout}><FiLogOut /></Link>
            </header>
            <main className="main">
                <Routes />
            </main>
        </div>
    );
}

export default Dashboard;