import axios from 'axios';

import { logout } from '../auth';

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL,
});

api.interceptors.request.use((request) => {
    const token = localStorage.getItem('token');
    if (token)
        request.headers.common.Authorization = `Bearer ${token}`;
    return request;
});

api.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status === 401) {
        logout();
    }
    return Promise.reject(error);
});

export default api;
