import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronLeft, FiPlus, FiSave, FiTrash } from 'react-icons/fi';

import api from '../../services/api';
import CompileExam from './compile';
import { useApiData } from '../../contexts/api-data';

const SingleExam = ({ match }) => {
    const apiData = useApiData();
    const [exam, setExam] = useState(null);
    const [questions, setQuestions] = useState([]);

    const nameInput = useRef(null);

    const { examId } = match.params;
    const dbQuestions = exam && exam.subject
        ? apiData.questions.filter(question => question.subject === exam.subject)
        : [];

    let subject;
    if (exam && exam.subject) {
        const subjectObject = apiData.subjects.find(sub => sub._id === exam.subject);
        subject = subjectObject
            ? (subjectObject.alias || subjectObject.name)
            : exam.subject;
    }

    useEffect(() => {
        (async function () {
            const response = await api.get(`/exams/${examId}`);

            const { exam } = response.data;
            setExam(exam);
            setQuestions(exam.questions.map(question => question._id));
        })();
    }, [examId]);

    function changeQuestion(event, index) {
        const newValue = event.target.value;
        questions[index] = newValue;
        setQuestions([...questions]);
    }

    function handleSave() {
        exam.name = nameInput.current.value;
        exam.questions = questions;
        apiData.updateExam(exam);
    }

    function removeQuestion(index) {
        questions.splice(index, 1);
        setQuestions(questions);
    }

    return (
        <>
            <div className="main-header">
                <Link to="/dashboard/exams" className="header-link"><FiChevronLeft /></Link>
                <h2>Exame</h2>
                <div className="main-header-actions">
                    <CompileExam examId={examId} />
                    <Link title="Salvar" className="header-link" to="#" onClick={handleSave}><FiSave /></Link>
                </div>
            </div>
            {exam && exam._id && (
                <div className="main-single-content">
                    <div className="exam-basic-info">
                        <input
                            type="text"
                            defaultValue={exam.name}
                            ref={nameInput}
                            placeholder="Nome"
                        />
                        <span>{subject || ''}</span>
                    </div>
                    <ul className="exam-question-list">
                        {questions.map((question, index) => (
                            <li key={index}>
                                <select
                                    value={question}
                                    onChange={event => changeQuestion(event, index)}>
                                    <option value="">Selecione uma questão</option>
                                    {dbQuestions.map((dbQuestion) => (
                                        <option
                                            key={dbQuestion._id}
                                            value={dbQuestion._id}
                                        >
                                            {dbQuestion.enunciation}
                                        </option>
                                    ))}
                                </select>
                                <Link
                                    className="header-link"
                                    onClick={() => removeQuestion(index)}
                                >
                                    <FiTrash />
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <Link className="header-link" onClick={() => setQuestions(questions.concat(''))}><FiPlus /></Link>
                </div>
            )}
        </>
    );
}

export default SingleExam;
